import http from '~/utils/http'


export class MainApi {
  // @POST('/jav/main/bannerJump')
  // Future<bool> bannerJump(@Body() AdJumpParam param);
  static bannerJump(params: AdJumpParam, opt?: HttpOptions) {
    return http.post<boolean>({ ...opt, url: '/jav/main/bannerJump', body: params })
  }

  // @POST('/jav/main/filter')
  // Future<FilterVideoPageResponse> filter(@Body() HomeVideoFilerParam param);
  static filter(params: HomeVideoFilerParam, opt?: HttpOptions) {
    return http.post<FilterVideoPageResponse>({ ...opt, url: '/jav/main/filter', body: params })
  }

  // @POST('/jav/main/queryBanner')
  // Future<List<BannerModel>> queryBanner(@Body() BannerParam param);
  static queryBanner(params: BannerParam, opt?: HttpOptions) {
    return http.post<Array<BannerModel>>({ ...opt, url: '/jav/main/queryBanner', body: params })
  }

  // @POST('/jav/main/queryBannerObj')
  // Future<BannerModel> queryBannerObj(@Body() BannerParam param);
  static queryBannerObj(params: BannerParam, opt?: HttpOptions) {
    return http.post<BannerModel>({ ...opt, url: '/jav/main/queryBannerObj', body: params })
  }

  // @POST('/jav/main/tabQuery')
  // Future<List<HomeTab>> tabQuery();
  static tabQuery(opt?: HttpOptions) {
    return http.post<Array<HomeTab>>({ ...opt, url: '/jav/main/tabQuery' })
  }

  // @POST('/jav/main/tagQuery')
  // Future<List<CategoryTag>> tagQuery();
  static tagCategoryQuery(opt?: HttpOptions) {
    return http.post<Array<CategoryTag>>({ ...opt, url: '/jav/main/tabQuery' })
  }

  // @POST('/jav/main/videoCom')
  // Future<VideoGroupPageResponse> videoCom(@Body() VideoComParam param);
  static videoCom(params: VideoComParam, opt?: HttpOptions) {
    return http.post<VideoGroupPageResponse>({ ...opt, url: '/jav/main/videoCom', body: params })
  }

  // @POST('/jav/main/videoComAll')
  // Future<VideoCombinePageResponse> videoComAll(@Body() VideoCombinesParam param);
  static videoComAll(params: VideoCombinesParam, opt?: HttpOptions) {
    return http.post<VideoCombinePageResponse>({ ...opt, url: '/jav/main/videoComAll', body: params })
  }
}
